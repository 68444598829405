<template>
  <div class="contenido">
    <b-modal v-model="isModalActive" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-modal>
      <template>
        <div class="container-modal container">
          <div class="parte-superior">
            <div>
              <span>
                <font-awesome-icon class="icono-modal white" :icon="['fal', 'lightbulb-on']" />
                <span class="title-m bold white">{{
                  $t("COMPETITION.STIMULUS_LOWER")
                }}</span>
                <span v-if="estimulosCreativos" class="date-stimulus title-m white">
                  {{ estimulosCreativos[indexSelectedEstimulo].fecha_inicio.split('T')[0] }}
                </span>
                <font-awesome-icon v-if="estimulosCreativos && estimulosCreativos.length !== 1" class="icono-modal white"
                  style="cursor: pointer;" :icon="['far', 'chevron-left']"
                  @click="indexSelectedEstimulo = (indexSelectedEstimulo - 1) < 0 ? estimulosCreativos.length - 1 : indexSelectedEstimulo - 1" />
                <font-awesome-icon v-if="estimulosCreativos && estimulosCreativos.length !== 1" class="icono-modal white"
                  style="cursor: pointer;" :icon="['far', 'chevron-right']"
                  @click="indexSelectedEstimulo = (indexSelectedEstimulo + 1) > estimulosCreativos.length - 1 ? 0 : indexSelectedEstimulo + 1" />
              </span>
            </div>
            <div>
              <button class="btn-modal btn button" @click="isModalActive = false">
                <span>
                  <font-awesome-icon class="icono" :icon="['fal', 'times']" />
                  <span class="text-l white">{{
                    $t("COMPETITION.CLOSE_STIMULUS")
                  }}</span>
                </span>
              </button>
            </div>
          </div>
          <div class="stimulus text-l gray">
            <div class="estimulo" v-if="estimulosCreativos">
              <span class="title-stimulus">{{ estimulosCreativos[indexSelectedEstimulo].titulo }}</span>
              <p>
                <img :src="'data:image;base64,' + estimulosCreativos[indexSelectedEstimulo].file_data"
                  :title="estimulosCreativos[indexSelectedEstimulo].orig_filename" alt="" />
              </p>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <ImageHeader :title="$t('EVALUATOR.TITILE')" />
    <ModalMessageBox :type="modalAttributes.type" :title="modalAttributes.title" :subtitle="modalAttributes.subtitle" />
    <div class="is-10 container-data container">
      <div class="container is-10 data">
        <div class="columns personal-data">
          <div class="is-5 is-offset-1 column has-text-left">
            <p style="margin-bottom: 18px; cursor: pointer;" @click="goToSelector">
              <font-awesome-icon style="margin-right: 10px;" :icon="['fal', 'arrow-left']" /> {{$t("EVALUATOR.BACK_TO_SELECTION")}}
            </p>
            <p class="title-m black bold">
              {{ user.nombre }} {{ user.primer_apellido }}
              {{ user.segundo_apellido }}
            </p>
            <br />
            <div class="text-m info-user">
              <span class="info-lit">{{ $t("MANAGER.PHASE", { faseNum: 2 }) }}</span>
              <span class="info-lit"><span class="icon-with-lit">
                  <font-awesome-icon :icon="['fal', 'globe']" /> {{ userEval.nombre }}
                </span></span>
              <span class="info-lit selector-zona bold black custom-select">
                <span class="icon-with-lit">
                  <font-awesome-icon :icon="['fal', 'map-marker-alt']" /> {{ $t("STIMULUS.DATA.PROVINCE") }}: {{ selectProvincia }}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="columns selectors">
          <div class="is-2 is-offset-1 column has-text-left">
            <button class="btn button" @click="isModalActive = true;">
              <span>
                <font-awesome-icon class="icono" :icon="['fal', 'lightbulb-on']" />
                <span class="text-m black">{{
                  $t("COMPETITION.STIMULUS")
                }}</span>
              </span>
            </button>
          </div>
          <div class="is-2 is-offset-6 column has-text-centered custom-select">
            <p class="title-m white bold">
              {{ $t("EVALUATOR.TASKS", { tasksNum: evaluaciones.length }) }}
            </p>
            <p class="text-m white download-relatos" @click="downloadRelatos()" style="padding-top: 5px">
              <font-awesome-icon :icon="['fal', 'print']" />
              {{ $t("EVALUATOR.PRINT") }}
            </p>
          </div>
        </div>
        <div class="columns" v-if="!finalizado">
          <div class="column is-10 is-offset-1">
            <p v-if="showAllPhase" class="message-order" v-html="$t('EVALUATOR.MESSAGE_REMAINING', { limitA: limitA })">
            <p v-if="!showAllPhase" class="message-order" v-html="$t('EVALUATOR.MESSAGE_REMAINING_SHORT')">
            </p>
          </div>
        </div>
        <div class="columns" style="padding-bottom: 10px;">
          <div v-if="showAllPhase" class="column is-5 is-offset-1 splitter" :class="{ 'disabled-section': numLiked === limitA }">
              <div class="column is-12">
                <div class="columns">
                  <div class="column is-8 header-section">
                    <span class="market-color">{{$t("EVALUATOR.STEP")}} 1:</span>
                    <span> {{ $t("EVALUATOR.SELECT", { limit: limitA }) }}</span>
                  </div>
                  <div class="column is-4 has-text-right">
                    <ProgressBar :noLiterals="false" :value="numLiked" :total="limitA" />
                  </div>
                </div>
              </div>
              <div class="column is-12 table-scroll">
                <table class="table">
                  <thead>
                    <tr>
                      <th v-for="(column, index) in columns" :key="index" scope="col" :class="{
                        'has-text-left': column.leftTitle,
                        'has-text-centered': column.centerTitle,
                        'has-text-right': column.rightTitle,
                      }">
                        <b class="text-m">{{ $t(column.title) }}</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in evaluaciones" :key="index" class="text-m gray bold">
                      <td class="has-text-centered contracte-field vertical-align-middle"
                        @click="goToRelatoDetail(item, index)">
                        {{ item.relato_id }}
                      </td>
                      <td class="has-text-left vertical-align-middle" @click="goToRelatoDetail(item, index)">
                        <span v-if="
                      item.necesidades_especiales && item.necesidades_especiales.length > 0
                    "><b-tag v-for="(necesidad, index) in item.necesidades_especiales" :key="index" type="is-warning" rounded>{{ necesidad }}</b-tag></span>
                        <span v-if="
                          item.titulo &&
                          item.titulo !== null &&
                          item.titulo !== ''
                        ">{{ item.titulo }}</span>
                        <span v-if="item.titulo === null || item.titulo === ''"><em>{{
                          $t("EVALUATOR.RELATO_W_T") }}</em></span>
                      </td>
                      <td class="has-text-right">
                        <div v-if="!finalizado">
                          <div class="icon-mark liked" :class="{ 'item-liked': item.nota === 'A' }"
                            @click="evaluarRelato(item, (item.nota === 'A' ? 'B' : 'A'))">
                            <span class="has-text-centered like-btn" :class="{ 'item-liked': item.nota === 'A' }">
                              <font-awesome-icon :icon="['fal', 'heart']" />
                            </span>
                          </div>
                        </div>
                        <div v-if="finalizado">
                          <div class="icon-mark liked item-liked" v-if="item.nota === 'A'">
                            <span class="has-text-centered like-btn" :class="{ 'item-liked': item.nota === 'A' }">
                              <font-awesome-icon :icon="['fal', 'heart']" />
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
          <div class="column is-5" :class="{ 'disabled-section': numLiked !== limitA, 'is-5': showAllPhase, 'is-10': !showAllPhase, 'is-offset-1': !showAllPhase }">
              <div v-if="showAllPhase" class="column is-12 header-section">
                <span class="market-color">{{$t("EVALUATOR.STEP")}} 2: </span>
                <span> {{$t("EVALUATOR.SORT")}}</span>
              </div>
              <div class="column is-12 table-scroll">
                <table class="table" style="margin-top: 10px;">
                  <thead>
                    <tr>
                      <th v-for="(column, index) in columnsDraggable" :key="index" scope="col" :class="{
                        'has-text-left': column.leftTitle,
                        'has-text-centered': column.centerTitle,
                        'has-text-right': column.rightTitle,
                      }">
                        <b class="text-m" v-if="(!finalizado || (column.title !== 'EVALUATOR.ORDER' && finalizado))">{{
                          $t(column.title) }}</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in evaluacionesA" :key="index" class="text-m gray bold" :class="{
                        first: index === 0,
                        second: index === 1,
                        third: index === 2,
                      }">
                      <td class="has-text-centered position-selector vertical-align-middle">
                        <b-field>
                          <b-select placeholder="Posición" v-model="item.posicion" @input="changeList(index, item)" class="select-position-eval" :disabled="finalizado">
                            <option :value="indexEval" v-for="(evalu, indexEval) in evaluacionesA" :key="indexEval">{{
                              indexEval + 1 }}
                            </option>
                          </b-select>
                        </b-field>
                      </td>
                      <td class="has-text-centered contracte-field vertical-align-middle" @click="goToRelatoDetail(item, index)">
                        {{ item.relato_id }}
                      </td>
                      <td class="has-text-left vertical-align-middle" @click="goToRelatoDetail(item, index)">
                        <span v-if="
                      item.necesidades_especiales && item.necesidades_especiales.length > 0
                    "><b-tag v-for="(necesidad, index) in item.necesidades_especiales" :key="index" type="is-warning" rounded>{{ necesidad }}</b-tag></span>
                        <span v-if="
                          item.titulo &&
                          item.titulo !== null &&
                          item.titulo !== ''
                        ">{{ item.titulo }}</span>
                        <span v-if="item.titulo === null || item.titulo === ''"><em>{{ $t("EVALUATOR.RELATO_W_T")
                        }}</em></span>
                      </td>
                      <td class="has-text-right">
                        <div v-if="!finalizado && showAllPhase">
                          <div class="icon-mark liked" :class="{ 'item-liked': item.nota === 'A' }"
                            @click="evaluarRelato(item, (item.nota === 'A' ? 'B' : 'A'))">
                            <span class="has-text-centered like-btn" :class="{ 'item-liked': item.nota === 'A' }">
                              <font-awesome-icon :icon="['fal', 'heart']" />
                            </span>
                          </div>
                        </div>
                        <div v-if="finalizado || !showAllPhase">
                          <div style="cursor: not-allowed;" class="icon-mark liked item-liked" v-if="item.nota === 'A'">
                            <span class="has-text-centered like-btn" :class="{ 'item-liked': item.nota === 'A' }">
                              <font-awesome-icon :icon="['fal', 'heart']" />
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
        </div>
      </div>
      <div class="btn-finish-container columns" v-if="!finalizado">
        <div class="is-3 is-offset-9 column has-text-right">
          <button class="btn-finish button" :disabled="
            (numLiked !== limitA)
          " @click="finalizarEvaluaciones()">
            <span class="text-l bold white">{{$t("EVALUATOR.FINISH_PROVINCE")}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageHeader from "@/components/ImageHeader.vue";
import ModalMessageBox from "@/components/ModalMessageBox.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import { FASES } from "../constants.js";

export default {
  name: "evaluadorf2",
  components: {
    ImageHeader,
    ModalMessageBox,
    ProgressBar,
  },
  data() {
    return {
      modalAttributes: {
        type: "",
        title: "",
        subtitle: "",
      },
      columns: [
        {
          title: "EVALUATOR.NUMBER",
          centerTitle: true,
        },
        {
          title: "EVALUATOR.TITLE_WORK",
          leftTitle: true,
        },
        {
          title: "EVALUATOR.MARK",
          rightTitle: true,
        },
      ],
      columnsDraggable: [
        {
          title: "EVALUATOR.POSITION",
          centerTitle: true,
        },
        {
          title: "EVALUATOR.NUMBER",
          centerTitle: true,
        },
        {
          title: "EVALUATOR.TITLE_WORK",
          leftTitle: true,
        },
        {
          title: "EVALUATOR.MARK",
          rightTitle: true,
        },
      ],
      evaluaciones: [],
      evaluacionesA: [],
      user: {},
      finalizado: true,
      userEval: {},
      selectProvincia: "",
      limitA: 10,
      showAllPhase: true,
      isModalActive: false,
      estimulosCreativos: undefined,
      indexSelectedEstimulo: 0
    };
  },
  created() {
    this.$store.commit("setShowSpinner", true);

    if(this.$route.params.provincia) {
      this.selectProvincia = this.$route.params.provincia;
    } else {
      this.goToSelector();
    }

    this.$store
      .dispatch("getUser")
      .then((user) => {
        this.user = user;
        this.$store
          .dispatch("getEstimulosByIdioma", {
            idioma: this.$i18n.locale,
          })
          .then((estimulos) => {
            this.estimulosCreativos = estimulos;
          });
        this.$store
          .dispatch("getUsuarioEvaluacion", {
            idioma: this.$i18n.locale,
            fase: FASES.EVALUACION_2,
          })
          .then((usuario_evaluacion) => {
            this.userEval = usuario_evaluacion[0];
            if (usuario_evaluacion.length > 0) {
              this.finalizado = usuario_evaluacion.find(eE => eE.nombre === this.selectProvincia).finalizado;

              this.$store.commit("setUsuarioEvaluaciones", usuario_evaluacion);
            }
            this.$store
              .dispatch("getEvaluaciones", {
                fase: FASES.EVALUACION_2,
                provincia: this.selectProvincia,
                idioma: this.$i18n.locale
              })
              .then((evaluaciones) => {
                this.$store.commit("setShowSpinner", false);
                evaluaciones = evaluaciones.map(obj => {
                  if (obj.necesidades_especiales) {
                    obj.necesidades_especiales = obj.necesidades_especiales.split(',');
                  } else {
                    obj.necesidades_especiales = [];
                  }
                  return obj;
                });
                let evaluacionesA = evaluaciones.filter((obj) => obj.nota === "A" && obj.posicion !== null).sort((a, b) => a.posicion - b.posicion);
                let evaluacionesANull = evaluaciones.filter(obj => obj.nota === 'A' && obj.posicion === null);
                if (evaluacionesANull.length > 0) {
                  this.evaluacionesA = evaluacionesA.concat(evaluacionesANull);
                } else {
                  this.evaluacionesA = evaluacionesA;
                }
                this.limitA = evaluaciones.length < 10 ? evaluaciones.length : 10;
                
                this.$store.commit("setAllEvaluaciones", evaluaciones);
                this.evaluaciones = evaluaciones;
                
                this.showAllPhase = this.evaluaciones.length > this.limitA;

                if(!this.showAllPhase && this.evaluacionesA.length === 0) {
                  this.evaluaciones.forEach(e => {
                    e.nota = 'A';
                    this.evaluacionesA.push(e)
                  });
                }

                this.evaluarA();

                localStorage.getItem('selectedFase2Tab') ? this.selectFilter = localStorage.getItem('selectedFase2Tab') : 'T';
              })
              .catch(() => {
                this.$store.commit("setShowSpinner", false);
              });
          });
      })
      .catch(() => {
        this.$store.commit("setShowSpinner", false);
      });
  },
  computed: {
    numLiked: function() {
      return this.evaluaciones.filter((obj) => obj.nota === 'A').length;
    }
  },
  methods: {
    goToSelector() {
      this.$router.push({ name: 'seleccion-provincia'});
    },
    finalizarEvaluaciones() {
      this.$buefy.dialog.confirm({
        title: this.$t("EVALUATOR.FINISH_TITLE"),
        message: this.$t("EVALUATOR.FINISH_TEXT"),
        confirmText: this.$t("COMPETITION.FINISH_LOWER"),
        cancelText: this.$t("COMPETITION.CANCELAR"),
        type: "is-danger",
        hasIcon: true,
        iconPack: "far",
        onConfirm: () => {
          this.$store
            .dispatch("finalizarEvaluaciones", {
              idioma: this.$i18n.locale,
              fase: FASES.EVALUACION_2,
              provincia: this.selectProvincia,
              email: this.user.email
            })
            .then((res) => {
              this.finalizado = true;
              
              this.$store.state.evaluadores.usuarioEvaluaciones.find(eE => eE.nombre === this.selectProvincia).finalizado = true;

              this.goToSelector();
            });
        },
      });
    },
    downloadRelatos() {
      this.$store.commit("setShowSpinner", true);
      this.$store.dispatch("downloadRelatos", {
        fase: FASES.EVALUACION_2,
        provincia: this.selectProvincia,
      }).then((response) => {
        const file = new Blob([response], {
          type: "application/pdf",
        });
        this.$store.commit("setShowSpinner", false);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = `relatos.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    changeList(index, item) {
      const evaluacion = this.evaluacionesA.splice(index, 1)[0];
      this.evaluacionesA.splice(item.posicion, 0, evaluacion);
      this.evaluarA();
    },
    evaluarA() {
      let evaluaciones = [];
      let evaluacionesACopy = JSON.parse(JSON.stringify(this.evaluacionesA));
      this.evaluacionesA = [];

      evaluacionesACopy.map((obj, index) => {
        obj.posicion = index;

        let evaluacion = {
          nota: obj.nota,
          relato_id: obj.relato_id,
          usuario_id: this.user.id,
          posicion: index,
          fase: FASES.EVALUACION_2
        }
        evaluaciones.push(evaluacion);
      });

      setTimeout(() => {
        this.evaluacionesA = evaluacionesACopy;
      }, 10);

      this.$store.dispatch("evaluarRelatos", {
        evaluaciones,
      }).then(res => {

      });
    },
    evaluarRelato(evaluacion, nota) {
      if (evaluacion.nota === "A") {
        this.$buefy.dialog.confirm({
          title: this.$t("EVALUATOR.MODIFICAR_TITLE"),
          message: this.$t("EVALUATOR.MODIFICAR_TEXT"),
          confirmText: this.$t("EVALUATOR.MODIFICAR_BUTTON"),
          cancelText: this.$t("COMPETITION.CANCELAR"),
          type: "is-danger",
          hasIcon: true,
          iconPack: "far",
          onConfirm: () => {
            const indexEval = this.evaluacionesA.findIndex((obj) => obj.relato_id === evaluacion.relato_id);
            this.evaluacionesA.splice(indexEval, 1);
            this.evaluarA();
            evaluacion.posicion = null;
            this.postEvaluacion(evaluacion, nota);
          },
        });
      } else {
        evaluacion.posicion = this.evaluacionesA.length;
        this.evaluacionesA.push(evaluacion);
        this.postEvaluacion(evaluacion, nota);
      }
    },
    goToRelatoDetail(evaluacion, index) {
      if (!this.finalizado) {
        this.$store.commit("setEvaluacionSlide", {
          index,
          evaluaciones: this.evaluaciones,
          limit: this.limitA
        });
        this.$router.push({
          name: "relato",
          params: { relatoId: evaluacion.relato_id, usuarioId: this.user.id },
        });
      }
    },
    postEvaluacion(evaluacion, nota) {
      this.$store.dispatch("evaluarRelato", {
        relato_id: evaluacion.relato_id,
        nota,
        posicion: evaluacion.posicion,
        fase: FASES.EVALUACION_2,
      }).then(res => {
        this.evaluaciones.find(e => e.relato_id == evaluacion.relato_id).nota = nota;
      });
    },
    setModalCompleted() {
      this.modalAttributes = {
        type: "warning",
        title: "Modificando un relato ya evaluado",
        subtitle: "",
      };
      this.$store.commit("setActiveModalCompleted", true);
    },
    setModalWarningEvaluacion() {
      this.modalAttributes = {
        type: "success",
        title: "Has evaluado todos tus relatos",
        subtitle:
          "Antes de finalizar tus evaluaciones, puedes revisarlas. Una vez hecho, finaliza con el botón 'Calificación terminada'.",
      };
      this.$store.commit("setActiveModalCompleted", true);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";

.personal-data {
  padding-top: 25px;
}

.selectors {
  background-color: $primary;
  margin-left: 0px;
  margin-right: 0px;
}

.container-data {
  margin-top: -150px;
  padding-bottom: 100px;
}

.container.data {
  background-color: white;
}

.titulo-seccion {
  margin-top: 10px;
  margin-bottom: 16px;

  display: flex;
  align-items: center;

  div {
    min-width: fit-content;
  }

  div:last-child {
    width: -webkit-fill-available;
    margin-left: 5%;
    height: 20px;
  }
}

hr {
  margin: 0.1rem 0;
}

.table {
  width: 100%;
  margin-bottom: 25px;
  border-collapse: separate;
  border-spacing: 0px 12px;
}

.table td,
.table th {
  border: none;
}

.table tbody tr:hover {
  cursor: pointer;
}

.table tbody tr {
  background-color: $background-box;
  height: 60px;

  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  td:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.first {
  background-color: #eed24440 !important;
  font-weight: bold;
}

.second {
  background-color: #cbcbcb40 !important;
  font-weight: bold;
}

.third {
  background-color: #c5935940 !important;
  font-weight: bold;
}

.icon-mark {
  background-color: white;
  padding: 10px;
  width: 41px;
  display: inline-grid;
  margin: 5px;
  border-radius: 50%;
  font-size: 17px;

  .arrow-btn {
    color: $primary
  }

  .like-btn {
    color: $green;
  }
}

.icon-mark.arrow.disabled {
  cursor: default;

  .arrow-btn {
    color: $color-footer;
  }
}

.icon-mark.arrow:hover {
  color: white;
  background-color: $primary;

  .arrow-btn {
    color: white;
  }
}

.icon-mark.arrow.disabled:hover {
  background-color: white;

  .arrow-btn {
    color: $color-footer;
  }
}

.icon-mark.liked:hover {
  color: white;
  background-color: $green;

  .like-btn {
    color: white;
  }
}

.icon-mark.unliked:hover {
  color: white;
  background-color: $primary;

  .un-like-btn {
    color: white;
  }
}

.item-liked {
  color: white !important;
  background-color: $green;
}

.un-like-btn {
  color: $primary;
}

.item-un-liked {
  color: white !important;
  background-color: $primary;
}

.btn-finish-container {
  position: relative;
  top: 10px;
}

.btn-finish {
  background-color: $primary;
  color: white;
  height: 60px;
  border-radius: 10px;
  // margin-bottom: 30px;
  width: 100%;
  margin-top: 10px;
}

.btn-finish:hover {
  color: white;
}

.btn {
  background-color: white;
  height: 50px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

  // width: 100%;
  .content-button {
    display: flex;
    align-items: center;
  }

  .icono {
    font-size: 20px;
    color: $primary;
    margin-right: 10px;
  }
}

.btn-more-evaluates {
  margin-top: -50px;
  background-color: white;
  border-radius: 20px;

  .icono {
    color: $primary;
    margin-right: 15px;
  }
}

.message-order {
  padding-top: 20px;
  font-size: 1.4em;
}

.header-section {
  font-size: 1.2em;
  font-weight: bold;
  display: flex;

  .market-color {
    margin-right: 8px;
    color: $primary;
  }
}

.info-user {
  .info-lit {
    padding-right: 22px;
  }

  display: flex;
  align-items: center;

  .selector-zona {
    display: flex;
    align-items: center;
  }
}

.download-relatos {
  cursor: pointer;
}

.icono-modal {
  font-size: 25px;
  margin-right: 20px;
}

.btn-modal {
  background-color: $primary;

  .icono {
    color: white;
  }
}

.stimulus {
  padding: 40px;
  background-color: white;
  margin-top: 25px;
  border-radius: 10px;
}

.parte-superior {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-stimulus {
  font-weight: bold;
  font-size: 22px;
}

.date-stimulus {
  margin-left: 15px;
  margin-right: 15px;
}

.tr-drag {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab !important;
  cursor: -moz-grab;
  cursor: -webkit-grab !important;
}

.position-selector {
  padding-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
}

.vertical-align-middle {
  vertical-align: middle;
}

.table-scroll{
  max-height: 700px;
  overflow-y: auto;

  thead {
    background-color: white;
    position: sticky;
    top:-12px;
    z-index: 5;
  }
}

.splitter{
  border-right: 1px solid $primary;
}

.disabled-section {
  pointer-events: none;
  opacity: 0.5;

  color: gray;

  .header-section {
    .market-color {
      color: gray;
    }
  }
}

.tag {
  margin-right: 8px;
}

</style>